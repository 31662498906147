import React from 'react'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
} from '@mui/material'
import Swal from 'sweetalert2'
import { editProfileQuery } from '../../config/axios'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

const FormEditProfile = ({ user, setUser, auth, setAuth }) => {
  const navigate = useNavigate()
  const [flag, setFlag] = useState(true)

  const handleSubmit = (event) => {
    event.preventDefault()
    editProfile(user)
  }

  const editProfile = async (user) => {
    // const {name, email, password, role} = user
    try {
      await editProfileQuery('/editar-perfil', user)

      Swal.fire({
        icon: 'success',
        title: 'Se ha editado el perfil correctamente',
        text: 'Inicia sesión nuevamente',
      })

      setUser({
        name: '',
        email: '',
        password: '',
        role: '',
        active: 1,
      })

      setAuth({
        isAuth: false,
        token: '',
        role: '',
        name: '',
        email: '',
      })

      localStorage.clear()

      navigate('/iniciar-sesion')
    } catch (error) {
      const { msg } = error.response.data
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error.',
        text: msg,
      })
    }
  }

  const handleInputChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }

  useEffect(() => {
    if (!user.currentPassword || !user.password) {
      setFlag(true)
    } else {
      setFlag(false)
    }
  }, [user.currentPassword, user.password])

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Card
        sx={{
          flexGrow: 1,
          px: 2,
          mx: 2,
        }}
      >
        <CardHeader
          subheader="Puedes editar esta información"
          title="Edita tu perfil"
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  helperText="Ingrese su nombre"
                  name="name"
                  onChange={handleInputChange}
                  value={user.name}
                  disabled
                  required
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  helperText="Correo electrónico"
                  name="email"
                  value={user.email}
                  required
                  disabled
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  type="password"
                  label="Contraseña actual"
                  helperText="Debes ingresar tu contraseña actual"
                  onChange={handleInputChange}
                  value={user.currentPassword}
                  name="currentPassword"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  type="password"
                  label="Contraseña nueva"
                  helperText="Debes ingresar la nueva contraseña"
                  onChange={handleInputChange}
                  value={user.password}
                  name="password"
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button disabled={flag} onClick={handleSubmit} variant="contained">
            Guardar cambios
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}

export default FormEditProfile
